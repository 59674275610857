























































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import dayjs from 'dayjs';
import cloneDeep from 'lodash.clonedeep';
import TextInputWithFormattedOverlay from '@/components/inputs/TextInputWithFormattedOverlay.vue';
import ManagerItem from '@/models/AssetValueAndCashFlow';
import MarketValueAndCashflowItem from '@/models/AssetAndMarketValueItem';
import { formatNumber, getStatusClass } from '@/utils';
import Manager from '@/models/Manager';
import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

interface ColumnHeading {
  effectiveDate: string;
  type: string;
  total: number;
}

@Component({
    components: {
        DatePicker,
        TextInputWithFormattedOverlay,
    },
})
export default class MarketValueAndCashflowTable extends Vue {
  public localAssetValueAndCashFlowData: ManagerItem[] = [];

  public originalData: ManagerItem[] = [];

  public dayJs = dayjs;

  public isNumber: boolean;

  public formatNumber = formatNumber;

  public getStatusClass = getStatusClass;

  public inputIssueMessage = {
      message: '',
      managerIndex: -1,
      itemIndex: -1,
      display: false,
  };


  @Prop({type: Array, required:true}) managerTableData!: ManagerItem[];

  @Prop({type: Array, required:true}) originalManagerTableData!: ManagerItem[];

  @Prop({type: Array, required:true}) columnData!: ColumnHeading[];


  @Emit('removeColumnFromList')
  deleteColumn (index: number): number {
      return index;
  }

  @Emit('highlightManagerInFocus')
  public highlightManager (managerIndex: number): void {
      this.$nextTick(() => {
          return managerIndex;
      });
  }

  get featureFlagIsOnForMultiCurrency () : boolean {
      return process.env.VUE_APP_FEATURE_MULTI_CURRENCY_CHANGES_IS_LIVE === 'true';
  }

  get currentRouteName (): string {
      return this.$route.name ?? '';
  }

  get marketOrExposureValueText (): string {
      return this.currentRouteName === 'MarketValueCashflow'
          ? 'Market Value'
          : 'Exposure Value';
  }

  get cashFlowOrExposureFlowText (): string {
      return this.currentRouteName === 'MarketValueCashflow'
          ? 'Cash Flow'
          : 'Exposure Flow';
  }

  public updateValue (
      item: MarketValueAndCashflowItem<string, null | number>,
      newValue: string,
      managerIndex: number,
      itemIndex: number
  ): void {
      let parsedValue: string | number = newValue.trim();
      // check for non acceptable inputs
      const regexFloat = new RegExp(/^[+-]?\d+(\.\d+)?$/);

      const valueBeforeUpdate =
      this.localAssetValueAndCashFlowData[managerIndex].assetValuesAndCashflows[
          itemIndex
      ].tickerValue;
      newValue = newValue.trim();
      newValue = newValue.replace(/,/g, '');
      // replace all non numbers except for .-+ with empty string
      newValue = newValue.replace(/[^0-9.,+-]/g, '');
      if (newValue !== '') {
          if (regexFloat.test(newValue)) {
              parsedValue =
          typeof newValue !== 'number' ? parseFloat(newValue) : newValue;
          } else {
              this.inputIssueMessage = {
                  message: 'Please input only numeric characters',
                  managerIndex,
                  itemIndex,
                  display: true,
              };
              setTimeout(() => {
                  this.inputIssueMessage = {
                      message: '',
                      managerIndex: -1,
                      itemIndex: -1,
                      display: false,
                  };
              }, 1500);
              parsedValue = valueBeforeUpdate;
          }
      }

      if (parsedValue > 10000000000000 || parsedValue < -10000000000000) {
          this.inputIssueMessage = {
              message: 'Value must be within range +/-10,000,000,000,000',
              managerIndex,
              itemIndex,
              display: true,
          };
          setTimeout(() => {
              this.inputIssueMessage = {
                  message: '',
                  managerIndex: -1,
                  itemIndex: -1,
                  display: false,
              };
          }, 1500);
          parsedValue = valueBeforeUpdate;
      } else {
          let edited = true;
          const matchesOriginalValue = this.originalManagerTableData[
              managerIndex
          ].assetValuesAndCashflows.find(
              (assetValue: MarketValueAndCashflowItem<number | string, number>) => {
                  return (
                      assetValue.rowID === item.rowID &&
            assetValue.tickerValue === parsedValue
                  );
              }
          );

          let toBeDeleted =
        parsedValue === '' && item.rowID !== null ? true : false;

          if (matchesOriginalValue || (item.rowID === null && parsedValue === '')) {
              edited = false;
          }

          const newItem = new MarketValueAndCashflowItem<string | number>(
              item.type,
              item.rowID,
              item.tickerID,
              item.effectiveDate,
              parsedValue,
              toBeDeleted,
              {
                  new: false,
                  edited: edited,
                  show: true,
                  isNewColumn: item.meta.isNewColumn,
              }
          );

          this.$emit('updateAssetValue', {
              item: newItem,
              managerIndex,
              itemIndex,
          });
      }
  }


  @Watch('managerTableData', { immediate: true, deep: true })
  private updatedColumn (newManagerData: Manager[]): void {
      this.originalData = cloneDeep(newManagerData);
      this.localAssetValueAndCashFlowData = cloneDeep(newManagerData);
  }
}
