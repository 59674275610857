var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-column-wrapper" }, [
    _c("div", { staticClass: "manager-mv-cf" }, [
      _c("label", [_vm._v("Select Asset Type")]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.assetType,
              expression: "assetType",
            },
          ],
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.assetType = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function ($event) {
                return _vm.updateErrorMessage($event.target.value)
              },
            ],
          },
        },
        [
          _c("option", { attrs: { value: "MMV" } }, [
            _vm._v(_vm._s(_vm.marketOrExposureValueText)),
          ]),
          _c("option", { attrs: { value: "CFNET3M" } }, [
            _vm._v(_vm._s(_vm.cashFlowOrExposureFlowText)),
          ]),
        ]
      ),
      _vm.valueError
        ? _c("div", { staticClass: "popup-error" }, [
            _vm._v("Please select a value"),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "manager-date" },
      [
        _c("label", [_vm._v("Select a date")]),
        _c("date-picker", {
          attrs: {
            format: "DD-MM-YYYY",
            clearable: false,
            "disabled-date": _vm.notAfterToday,
          },
          on: {
            change: function ($event) {
              return _vm.updateErrorMessage($event)
            },
          },
          model: {
            value: _vm.effectiveDate,
            callback: function ($$v) {
              _vm.effectiveDate = $$v
            },
            expression: "effectiveDate",
          },
        }),
        _vm.dateError
          ? _c("div", { staticClass: "popup-error" }, [
              _vm._v("Please select a date"),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm.dateTypeConflict
      ? _c("div", { staticClass: "popup-error" }, [
          _vm._v(
            "The selected date and value type already exist in current table"
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "flex btn-wrapper" }, [
      _c(
        "div",
        { staticClass: "btn btn--discard", on: { click: _vm.cancelAddColumn } },
        [_vm._v("Cancel")]
      ),
      _c(
        "div",
        { staticClass: "btn btn--default", on: { click: _vm.addColumn } },
        [_vm._v("Add")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }