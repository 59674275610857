






























import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import dayjs from 'dayjs';
import ManagerItem from '@/models/AssetValueAndCashFlow';
import MarketValueAndCashflowItem from '@/models/AssetAndMarketValueItem';

@Component({
    components: {
        DatePicker,
    },
})
export default class AddColumnPopUp extends Vue {
  @Prop({type: Array, required: true }) sortedManagerData: ManagerItem[];

  public effectiveDate = '';

  public assetType = '';

  public dateError = false;

  public valueError = false;

  public notAfterToday (date: string): boolean {
      const dateNow = dayjs();
      const thisDate = dayjs(date);
      return thisDate > dateNow;
  }

  get currentRouteName (): string {
      return this.$route.name ?? '';
  }

  get marketOrExposureValueText (): string {
      return this.currentRouteName === 'MarketValueCashflow'
          ? 'Market Value'
          : 'Exposure Value';
  }

  get cashFlowOrExposureFlowText (): string {
      return this.currentRouteName === 'MarketValueCashflow'
          ? 'Cash Flow'
          : 'Exposure Flow';
  }

  public addColumn (): void {
      if (!this.isValid()) {
          return;
      }
      if (!this.dateTypeConflict) {
          this.$emit('addNewColumnData', {
              effectiveDate: this.effectiveDate,
              type: this.assetType,
              total: 0,
          });
          this.resetValues();
      }
  }

  private isValid (): boolean {
      if (!this.effectiveDate && !this.valueError) {
          this.dateError = true;
          this.valueError = true;
          return false;
      }

      if (!this.effectiveDate) {
          this.dateError = true;
          return false;
      }

      if (!this.assetType) {
          this.valueError = true;
          return false;
      }

      return true;
  }

  get dateTypeConflict (): boolean {
      let returnValue = false;
      this.sortedManagerData.forEach((manager: ManagerItem) => {
          const dataPointExists = manager.assetValuesAndCashflows.findIndex(
              (assetValue: MarketValueAndCashflowItem<string | number, number>) =>
                  dayjs(assetValue.effectiveDate).format('DD/MM/YYYY') ===
            dayjs(this.effectiveDate).format('DD/MM/YYYY') &&
          this.assetType === assetValue.type
          );
          if (dataPointExists > -1) {
              returnValue = true;
              return;
          }
      });
      return returnValue;
  }

  updateErrorMessage (value: string): void {
      if (dayjs(value).isValid()) {
          this.dateError = false;
      }
      if (value === 'MMV' || value === 'CFNET3M') {
          this.valueError = false;
      }
  }

  private resetValues (): void {
      this.effectiveDate = '';
      this.assetType = '';
  }

  @Emit('closeOptions')
  cancelAddColumn (): void {
      this.resetValues();
  }
}
