var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table" },
    [
      _c(
        "div",
        { staticClass: "tr" },
        _vm._l(_vm.columnData, function (item, index) {
          var _obj
          return _c(
            "div",
            {
              key: index,
              staticClass: "td align-center column-date",
              class: ((_obj = {}), (_obj["column-head-" + index] = true), _obj),
            },
            [
              _c("div", { staticClass: "flex flex--row-center" }, [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.dayJs(item.effectiveDate).format("DD MMM YYYY")
                      )
                  ),
                ]),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Delete column",
                        expression: "'Delete column'",
                      },
                    ],
                    staticClass: "delete-icon",
                    on: {
                      click: function ($event) {
                        return _vm.deleteColumn(index)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "trash-alt"] },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "tr" },
        _vm._l(_vm.columnData, function (item, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Enter full value amounts (eg 1,000,000)",
                  expression: "'Enter full value amounts (eg 1,000,000)'",
                },
              ],
              key: index,
              staticClass: "td align-center column-header",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    item.type === "MMV"
                      ? _vm.marketOrExposureValueText
                      : _vm.cashFlowOrExposureFlowText
                  ) +
                  " "
              ),
            ]
          )
        }),
        0
      ),
      _vm._l(
        _vm.localAssetValueAndCashFlowData,
        function (manager, managerIndex) {
          return _c(
            "div",
            { key: manager.id, staticClass: "tr" },
            _vm._l(manager.assetValuesAndCashflows, function (item, itemIndex) {
              return _c(
                "div",
                { key: itemIndex, staticClass: "td data-input" },
                [
                  _c("TextInputWithFormattedOverlay", {
                    attrs: {
                      value:
                        item.tickerValue === ""
                          ? item.tickerValue
                          : item.tickerValue.toFixed(2),
                      statusClass: _vm.getStatusClass(item),
                      tabindex: itemIndex + 1,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.updateValue(
                          item,
                          $event.target.value,
                          managerIndex,
                          itemIndex
                        )
                      },
                      focus: function ($event) {
                        return _vm.highlightManager(managerIndex)
                      },
                    },
                  }),
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      _vm.inputIssueMessage.display &&
                      _vm.inputIssueMessage.managerIndex === managerIndex &&
                      _vm.inputIssueMessage.itemIndex === itemIndex
                        ? _c("span", { staticClass: "warning-message" }, [
                            _vm._v(_vm._s(_vm.inputIssueMessage.message)),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          )
        }
      ),
      _c(
        "div",
        { staticClass: "tr" },
        _vm._l(_vm.columnData, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "td align-right total-value" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.formatNumber(item.total, 2, ".", ",", "")) +
                  " "
              ),
            ]
          )
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }